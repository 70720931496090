@use '@carbon/styles/scss/reset';
@use './code.scss';

/* BASICS */
a {
  color: hsl(0, 0, 0);
  text-decoration: none;
}

html {
  font-family: 'Public Sans', sans-serif;
  font-size: 19px;
}

li {
  font-size: 1rem;
}

code {
  font-family: 'IBM Plex Mono', monospace;
}
